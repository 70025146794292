'use client';
import { useContext } from 'react';
import { FallbackImage } from 'components/UI/FallbackImage';
import Link from 'components/UI/Link/Link';
import { TeaserGridSection } from 'components/UI/TeaserGridSection';
import { TeaserGridSection as TeaserGridSectionNew } from 'components/UI/TeaserGridSectionNew';
import type { TitleLink } from 'components/UI/Title/Title';
import { Typography } from 'components/UI/Typography';
import { AppContext } from 'context/AppContext';
import { isFeatureFlagEnabled } from 'utils/featureFlags';

export type WidgetTeaserRtlPlusProps = {
  readonly model?: {
    readonly fields?: {
      readonly title?: string;
      readonly link?: TitleLink;
      readonly anchor?: string;
    };
  };
  readonly items?: ReadonlyArray<{
    readonly model: {
      readonly id: string;
      readonly utm_URL?: string;
      readonly fields: {
        readonly title: string;
        readonly url: string;
      };
      readonly image?: {
        readonly defaultUrl: string;
      };
    };
  }>;
};

export const isRtlPlusType = (type: string): boolean => type === 'rtl-plus-episodes' || type === 'rtl-plus-series';

const WidgetTeaserRtlPlus = ({ context }: { context: WidgetTeaserRtlPlusProps }) => {
  const { featureFlags } = useContext(AppContext);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);
  const styles = isRefresh
    ? require('./widget-teaser-rtl-plus-new.module.scss')
    : require('./widget-teaser-rtl-plus.module.scss');

  const items = context.items;

  const TeaserGridSectionComponent = isRefresh ? TeaserGridSectionNew : TeaserGridSection;

  return (
    <div className={styles.background}>
      <TeaserGridSectionComponent
        variant="rtl-plus"
        title={context.model?.fields?.title}
        titleLink={context.model?.fields?.link}
        isRtlPlus
        anchor={context.model?.fields?.anchor}
      >
        {items?.map((item) => (
          <div className={styles.teaser} key={item.model.id}>
            <div className={styles.imageWrapper}>
              {item.model?.image?.defaultUrl ? (
                /* eslint-disable-next-line @next/next/no-img-element */
                <img
                  src={item.model.image.defaultUrl}
                  className={styles.image}
                  alt={`Bild zu: "${item.model.fields.title}"`}
                />
              ) : (
                <FallbackImage className={styles.image} />
              )}
            </div>
            <Link
              className={styles.link}
              href={item.model.utm_URL || item.model.fields.url || ''}
              target="_blank"
              noFollow={false}
            >
              <div className={styles.textWrapper}>
                <h3 className={styles.h3}>
                  <Typography className={styles.headline} as="div" variant="s">
                    {item.model.fields.title}
                  </Typography>
                </h3>
              </div>
            </Link>
          </div>
        ))}
      </TeaserGridSectionComponent>
    </div>
  );
};

export default WidgetTeaserRtlPlus;
