'use client';
import { useContext } from 'react';
import { TeaserGridSection } from 'components/UI/TeaserGridSection';
import { TeaserGridSection as TeaserGridSectionNew } from 'components/UI/TeaserGridSectionNew';
import { TeaserNew } from 'components/UI/TeaserNew';
import TeaserVideo from 'components/UI/TeaserVideo/TeaserVideo';
import type { TitleLink } from 'components/UI/Title/Title';
import { AppContext } from 'context/AppContext';
import type { WidgetTeaserItemProps } from 'types/cms-teaser';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import { linkUrl } from 'utils/url';

export type WidgetTeaserVideoProps = {
  readonly model?: {
    readonly fields?: {
      readonly title?: string;
      readonly link?: TitleLink;
      readonly anchor?: string;
    };
  };
  readonly items?: ReadonlyArray<WidgetTeaserItemProps>;
};

const WidgetTeaserVideo = ({ context }: { context: WidgetTeaserVideoProps }) => {
  const { featureFlags } = useContext(AppContext);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);
  const isTeaserRefresh = isFeatureFlagEnabled('ft_temp_enable_refresh_teasers', featureFlags);

  const width = 370;

  if (isRefresh || isTeaserRefresh) {
    return (
      <TeaserGridSectionNew
        title={context.model?.fields?.title}
        titleLink={context.model?.fields?.link}
        anchor={context.model?.fields?.anchor}
      >
        {context.items?.map((item) => (
          <TeaserNew
            key={item.model.id}
            title={item.model.fields.title}
            image={{
              image: item.model.image,
              width: width,
              alt: item.model?.image?.fields?.alt?.value
                ? item.model.image?.fields.alt.value
                : `Bild zu: "${item.model.fields.title}"`,
            }}
            linkHref={linkUrl(item.model.url)}
            isVideo
            videoDuration={Number(item.model.fields.encoding?.[0]?.value?.duration?.value)}
          />
        ))}
      </TeaserGridSectionNew>
    );
  }

  return (
    <TeaserGridSection
      title={context.model?.fields?.title}
      titleLink={context.model?.fields?.link}
      anchor={context.model?.fields?.anchor}
    >
      {context.items?.map((item) => (
        <TeaserVideo
          key={item.model.id}
          headline={item.model.fields.title}
          kicker={item.model.fields.subtitle}
          image={{ image: item.model.image, width: width, alt: `Bild zu: "${item.model.fields.title}"` }}
          link={linkUrl(item.model.url)}
        />
      ))}
    </TeaserGridSection>
  );
};

export default WidgetTeaserVideo;
