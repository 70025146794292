import { Fragment, useContext } from 'react';
import { clsx } from 'clsx';
import type { WidgetSeriesProps } from 'components/partials/widget/widget-series/widget-series-default';
import { isRtlPlusType } from 'components/partials/widget/widget-teaser/widget-teaser-button/widget-teaser-button';
import { AppContext } from 'context/AppContext';
import { AdSlotType, type AdsProps, type AdsPropsInList } from 'types/ads';
import type { TeaserTypes, WidgetTeaserItemProps, WidgetTeaserStandardProps } from 'types/cms-teaser';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import { generateWeakKey } from 'utils/idGenerator';
import { AdSlot } from '../AdSlot';
import { getAdType } from '../AdSlot/AdSlotHelper';
import { Gallery } from '../Gallery';
import { Teaser } from '../Teaser';
import { TeaserNew } from '../TeaserNew';
import styles from './AdInsideList.module.scss';

interface PlayableAdsProps {
  [positionInList: number]: AdItemProp;
}

export interface AdItemProp {
  [type: string]: AdsProps;
}

interface PlayableItem {
  isAd?: boolean;
  teaserItem?: WidgetTeaserItemProps;
  adItem?: AdItemProp;
}

/*
Search for ads in models
Handling the aps-position to simplify editing in site-manager
*/
export const findPlayableAds = (
  context: WidgetTeaserStandardProps | WidgetSeriesProps,
  skipPos?: boolean
): PlayableAdsProps => {
  const ads: PlayableAdsProps = {};

  const decreaseIndex = {
    [`${AdSlotType.Desktop}`]: 0,
    [`${AdSlotType.Mobile}`]: 0,
  };

  context.model?.fields?.adSlots?.forEach((ad: AdsPropsInList) => {
    const type = getAdType(ad.name);
    let pos = ad.positionInList;
    if (!skipPos) {
      pos += decreaseIndex[type];
    }

    if (!ads[pos]) {
      ads[pos] = {};
    }

    ads[pos][type] = {
      name: ad.name,
      position: ad.position,
      outbrainWidgetId: ad.outbrainWidgetId,
    };

    decreaseIndex[type] -= 1;
  });

  return ads;
};

const rearrangeItemsList = (context: WidgetTeaserStandardProps, offset: number = 0): Array<PlayableItem> => {
  if (!context?.items || context.items.length === 0) {
    return [];
  }

  const ads = findPlayableAds(context);
  const items: Array<PlayableItem> = [];

  context.items.forEach((item, index) => {
    if (ads[index + offset + 1]) {
      items.push({ isAd: true, adItem: ads[index + offset + 1] });
    }
    items.push({ teaserItem: item });
  });

  if (ads[context.items.length + offset + 1]) {
    items.push({ isAd: true, adItem: ads[context.items.length + offset + 1] });
  }

  return items;
};

const getViewport = (name: string) => {
  switch (name) {
    case 'mobile':
    case 'outbrain-mobile':
    case 'dmofooter':
      return AdSlotType.Mobile;
    case 'billboard':
    case 'outbrain':
    case 'rectangle':
      return AdSlotType.Desktop;
    default:
      return AdSlotType.Default;
  }
};

export const RenderAd = ({ el }: { el: AdItemProp }) => {
  const { featureFlags } = useContext(AppContext);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);
  const isTeaserRefresh = isFeatureFlagEnabled('ft_temp_enable_refresh_teasers', featureFlags);

  return (
    <>
      {Object.entries(el).map(([type, ad]) => (
        <AdSlot
          key={`adSlot-${type}-${ad.name}-${ad.position}`}
          name={ad.name}
          className={clsx({ [styles.adslot]: isRefresh || isTeaserRefresh })}
          position={ad.position}
          outbrainWidgetId={ad.outbrainWidgetId}
          viewport={getViewport(ad.name)}
          skipPrediction={isRefresh || isTeaserRefresh}
        />
      ))}
    </>
  );
};

const RenderTeaser = ({
  teaser,
  index,
  teaserImageWidth,
  leadTeaserImageWidth,
  firstTeaserHighlight,
}: {
  teaser: WidgetTeaserItemProps;
  index: number;
  teaserImageWidth?: number;
  leadTeaserImageWidth?: number;
  firstTeaserHighlight?: boolean;
}) => {
  const { featureFlags } = useContext(AppContext);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);
  const isTeaserRefresh = isFeatureFlagEnabled('ft_temp_enable_refresh_teasers', featureFlags);

  const itemHasExternalLink = teaser.model.fields.externalUrl !== undefined && teaser.model.fields.externalUrl !== '';
  const isLeadImage = leadTeaserImageWidth && index === 0;

  if (teaser.type === 'gallery') {
    return (
      <Gallery
        teaser={teaser}
        teaserImageWidth={teaserImageWidth}
        leadTeaserImageWidth={leadTeaserImageWidth}
        index={index}
      />
    );
  }

  if (isRefresh || isTeaserRefresh) {
    const isRtlPlus = isRtlPlusType(teaser.type);
    const link = isRtlPlus
      ? (teaser.model?.fields?.url ?? teaser.model.url)
      : (teaser.model?.fields?.externalUrl ?? teaser.model.url);

    return (
      <TeaserNew
        title={teaser.model.fields.title}
        subtitle={teaser.model.themenwelt?.section_name}
        image={{
          image: teaser.model.image,
          width: isLeadImage ? leadTeaserImageWidth : teaserImageWidth,
          alt: teaser.model?.image?.fields?.alt?.value
            ? teaser.model.image?.fields.alt.value
            : `Bild zu: "${teaser.model.fields.title}"`,
        }}
        linkHref={link}
        variant={isLeadImage ? 'large' : 'medium'}
        subtitleColor={teaser.model.themenwelt?.secondary_color}
        isHighlight={firstTeaserHighlight && index === 0}
        isVideo={!!teaser.model.fields.encoding?.[0]?.value?.duration?.value}
        videoDuration={Number(teaser.model.fields.encoding?.[0]?.value?.duration?.value)}
        isSponsored={teaser.type === 'sponsored-article'}
        isRtlPlus={isRtlPlus}
      />
    );
  }

  return (
    <Teaser
      headline={teaser.model.fields.title}
      kicker={teaser.model.fields.subtitle}
      image={{
        image: teaser.model.image,
        width: isLeadImage ? leadTeaserImageWidth : teaserImageWidth,
        alt: teaser.model.fields.title,
      }}
      linkHref={itemHasExternalLink ? teaser.model.fields.externalUrl : teaser.model.url}
      isSmallOnMobile
      hasIntroVideo={teaser.model.hasVideo}
      isSponsored={teaser.type === 'sponsored-article'}
      variant={isLeadImage ? 'lead' : 'default'}
      isGallery={teaser.type === ('gallery' as TeaserTypes)}
    />
  );
};

export const AdInsideList = ({
  context,
  teaserImageWidth,
  leadTeaserImageWidth,
  firstTeaserHighlight = false,
  offset = 0,
}: {
  context: WidgetTeaserStandardProps;
  teaserImageWidth?: number;
  leadTeaserImageWidth?: number;
  firstTeaserHighlight?: boolean;
  offset?: number;
}) => {
  const items = rearrangeItemsList(context, offset);

  return (
    <>
      {items.map((el, index) => (
        <Fragment key={`${generateWeakKey(el)}`}>
          {el.adItem && <RenderAd el={el.adItem} />}
          {el.teaserItem && (
            <RenderTeaser
              teaser={el.teaserItem}
              index={index}
              teaserImageWidth={teaserImageWidth}
              leadTeaserImageWidth={leadTeaserImageWidth}
              firstTeaserHighlight={firstTeaserHighlight}
            />
          )}
        </Fragment>
      ))}
    </>
  );
};
