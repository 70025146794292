'use client';
import { useContext } from 'react';
import { clsx } from 'clsx';
import { FallbackImage } from 'components/UI/FallbackImage';
import Link from 'components/UI/Link/Link';
import { TeaserGridSection } from 'components/UI/TeaserGridSection';
import { TeaserGridSection as TeaserGridSectionNew } from 'components/UI/TeaserGridSectionNew';
import type { TitleLink } from 'components/UI/Title/Title';
import { Typography } from 'components/UI/Typography';
import { AppContext } from 'context/AppContext';
import { isFeatureFlagEnabled } from 'utils/featureFlags';

export type WidgetTeaserRtlPlusTop5Props = {
  readonly model?: {
    readonly fields?: {
      readonly title?: string;
      readonly link?: TitleLink;
      readonly anchor?: string;
    };
  };
  readonly items?: ReadonlyArray<{
    readonly model: {
      readonly id: string;
      readonly fields: {
        readonly genres?: ReadonlyArray<string>;
        readonly genre?: ReadonlyArray<{ value: string }>;
      };
      readonly title: string;
      readonly url: string;
      readonly image?: {
        readonly defaultUrl: string;
      };
    };
  }>;
};

const WidgetTeaserRtlPlusTop5 = ({ context }: { context: WidgetTeaserRtlPlusTop5Props }) => {
  const { featureFlags } = useContext(AppContext);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);
  const styles = isRefresh
    ? require('./widget-teaser-rtl-plus-top5-new.module.scss')
    : require('./widget-teaser-rtl-plus-top5.module.scss');

  const items = context.items;

  const TeaserGridSectionComponent = isRefresh ? TeaserGridSectionNew : TeaserGridSection;

  return (
    <div className={styles.background}>
      <TeaserGridSectionComponent
        variant="rtl-plus-top5"
        title={context.model?.fields?.title}
        titleLink={context.model?.fields?.link}
        titleAs="div"
        gridColumns={5}
        isRtlPlus
        anchor={context.model?.fields?.anchor}
      >
        {items?.map((item, index) => {
          const genres = item.model?.fields?.genres ?? item.model?.fields?.genre?.map((genre) => genre.value);

          return (
            <div className={styles.teaser} key={item.model.id}>
              <div className={styles.imageWrapper}>
                {item.model?.image?.defaultUrl ? (
                  /* eslint-disable-next-line @next/next/no-img-element */
                  <img
                    src={item.model.image.defaultUrl}
                    className={styles.image}
                    alt={`Bild zu: "${item.model.title}"`}
                  />
                ) : (
                  <FallbackImage className={styles.image} />
                )}

                <Typography className={clsx(styles.number, styles[`number${index + 1}`])} variant="l" as="span">
                  {index + 1}
                </Typography>
              </div>
              <Link className={styles.link} href={item.model.url} target="_blank" noFollow={false}>
                <div className={styles.textWrapper}>
                  {genres && genres.length > 0 && (
                    <Typography className={styles.subtitle} variant="4xs" as="div">
                      {genres.map((genre) => genre).join(' / ')}
                    </Typography>
                  )}
                  <Typography className={styles.headline} as="div" variant="s">
                    {item.model.title}
                  </Typography>
                </div>
              </Link>
            </div>
          );
        })}
      </TeaserGridSectionComponent>
    </div>
  );
};

export default WidgetTeaserRtlPlusTop5;
